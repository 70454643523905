import { endOfDay } from 'date-fns'

export enum NotFound {
  ClientApp = '...',
  Control = 'NOT FOUND',
}

export enum NotApplicable {
  ClientApp = 'N/A',
  Control = 'NOT APPLICABLE',
}

type Constants = {
  notFound: NotFound
  notApplicable: NotApplicable
}

const appConstants: { [key: string]: Constants; control: Constants; clientApp: Constants } = {
  control: {
    notFound: NotFound.Control,
    notApplicable: NotApplicable.Control,
  },
  clientApp: {
    notFound: NotFound.ClientApp,
    notApplicable: NotApplicable.ClientApp,
  },
}

const packageName = process.env['NEXT_PUBLIC_NP_APP_NAME']
const constants: Constants =
  packageName === 'clientApp' ? appConstants.clientApp : appConstants.control

export const RATIO_UNIT = 'ratio'
export const PERCENTAGE = '%'
export const ONE_MILLION = 1000000
export const ONE_BILLION = 1000000000
export const NOT_FOUND = constants.notFound
export const NOT_APPLICABLE = constants.notApplicable
export const UNPROCESSABLE_UNIT = 'UNPROCESSABLE'

export const ISSB_MAJOR_CODE = 'IFRS S2'

export const FIVE_SECONDS_MS = 5 * 1000
export const ONE_HOUR_MS = 60 * 60 * 1000
export const TWELVE_HOURS_MS = 12 * ONE_HOUR_MS

export const AUTO_PAUSE_TASK_MS = 10 * 60 * 1000
export const PROMPT_TIMEOUT_MS = 60 * 1000

export const TIME_TRACK_INTERVAL_MS = 5 * 1000

export const MIN_ESTIMATE_DATE = new Date('2017-01-01')
export const MAX_ESTIMATE_DATE = endOfDay(new Date())

export const CURRENCY_UNIT_ID = 3
export const GB_POUND_UNIT_ID = 8
export const US_DOLLAR_UNIT_ID = 11
export const US_DOLLAR_THOUSAND_UNIT_ID = 48
export const US_DOLLAR_MILLION_UNIT_ID = 41
export const US_DOLLAR_BILLION_UNIT_ID = 44
export const US_DOLLAR_TRILLION_UNIT_ID = 402
export const MWH_UNIT_ID = 51
export const BOOLEAN_UNIT_ID = 839

export const CDP_SRC_ID = 5034

export const ALL_MAJOR_METRICS_LABEL = 'All Metrics'
export const ALL_MAJOR_METRICS_ID = '00000000-0000-0000-0000-000000000000'
export const NP_METRIC_CONFIG = 'f168c3e7-647b-44c0-b2f4-2ee80dd13011' // Net Purpose metric config we provide to users that they cannot modify
export const NP_METRIC_CONFIG_NAME = 'SDG Contribution'
export const DEMO_METRIC_CONFIG = '0fc6ea1e-80a5-481a-9f7a-837c61f19962'
