import { Activity, getApiConfig, Portfolio } from '@netpurpose/api'
import { TablePaginationConfig, Theme } from '@netpurpose/types'

const PAGE_SIZE = 50

const featureToggles = {
  portfolioPAIsExport: 'client-app.portfolio-PAIs-export',
  portfolioSDGsExport: 'client-app.portfolio-SDGs-export',
  incompleteDisclosureIcon: 'client-app.disclosure-incomplete-icon',
  estimationModal: 'client-app.estimation-modal',
  editMetricConfigEstimations: 'client-app.edit-metric-config-estimations',
  fundOfFunds: 'client-app.fund-of-funds',
} as const

const routes = {
  landingPage: '/portfolios',
  login: '/login',
  jupiterSSOTestLogin: '/login/jupiter',
  explore: '/explore',
  portfolioList: '/portfolios',
  portfolioCreation: '/portfolios/create',
  library: '/library',
  goals: '/goals',
  forgotPassword: '/forgot-password',
  register: '/register',
  signup: '/sign-up',
  enrollMultifactor: '/enroll-mfa',
  metricConfigs: '/metric-configs',
} as const

const dynamicRoutes = {
  portfolioDetail: (portfolioId: Portfolio['id'], activeTab?: string | undefined) =>
    `${routes.portfolioList}/${portfolioId}${activeTab ? `?active-tab=${activeTab}` : ''}`,
  portfolioDetailMetricConfigs: (portfolioId: Portfolio['id'], fromDisclosure?: boolean) =>
    `${routes.portfolioList}/${portfolioId}/metric-configs${fromDisclosure ? '?from_tab=disclosure' : '?from_tab=impact'}`,
  portfolioEntityDetail: (portfolioId: Portfolio['id'], entityId: number) =>
    `${routes.portfolioList}/${portfolioId}/${entityId}`,
  portfolioEntityDetailMetricConfigs: (portfolioId: Portfolio['id'], entityId: number) =>
    `${routes.portfolioList}/${portfolioId}/${entityId}/metric-configs`,
  comparePortfolios: (portfolioIds: Portfolio['id'][]) =>
    `${routes.portfolioList}/compare/${portfolioIds[0]}/${portfolioIds[1]}`,
  comparePortfoliosMetricConfigs: (portfolioIds: Portfolio['id'][]) =>
    `${routes.portfolioList}/compare/${portfolioIds[0]}/${portfolioIds[1]}/metric-configs`,
  entityDetail: (entityId: number) => `${routes.explore}/${entityId}`,
  entityDetailMetricConfigs: (entityId: number) => `${routes.explore}/${entityId}/metric-configs`,
  entityDataDetail: (entityId: number, theme?: Theme) =>
    `${dynamicRoutes.entityDetail(entityId)}?active-tab=data${theme ? `&theme=${theme}` : ''}`,
  estimationDetail: (entityId: number, techIds: Activity['name'][], portfolioId?: number) =>
    `${dynamicRoutes.entityDetail(entityId)}/estimation?techIds=${encodeURIComponent(techIds.join('-'))}${portfolioId ? `&portfolioId=${portfolioId}` : ''}`,
  metricConfig: (metricConfigId: string) => `${routes.metricConfigs}/${metricConfigId}`,
  domainDetail: (taxonId: number) => `${routes.explore}/taxonomy/domain/${taxonId}`,
  solutionDetail: (taxonId: number) => `${routes.explore}/taxonomy/solution/${taxonId}`,
  baselineDetail: (taxonId: number) => `${routes.explore}/taxonomy/baseline/${taxonId}`,
} as const

export type RouteName = keyof typeof routes
export type DynamicRouteName = keyof typeof dynamicRoutes

const localStorageKeys = {
  dataView: 'netpurpose:application:selectedDataView',
} as const

const appConfig = {
  packageName: process.env['NEXT_PUBLIC_NP_APP_NAME'] || '',
  version: process.env['NEXT_PUBLIC_VERSION'] || '',
  deploymentEnv: process.env['NEXT_PUBLIC_DEPLOYMENT_ENV'] || '',
  localStorageKeys,
  routes,
  dynamicRoutes,
  unprotectedRoutes: [
    'login',
    'forgotPassword',
    'register',
    'signup',
    'jupiterSSOTestLogin',
  ] satisfies RouteName[],
  taskResultsPollDelay: 5000,
  locale: 'en-US',
  defaultCountry: 'US', // Two-letter country code - https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
  defaultFetchSize: PAGE_SIZE,
  defaultTablePagination: {
    showSizeChanger: false,
    hideOnSinglePage: true,
    pageSize: PAGE_SIZE,
  } satisfies TablePaginationConfig,
  featureToggles,
  privacyPolicyUrl: 'https://app.privasee.io/privacy-portal/646cc22bda2352001473b13e',
  pipeDriveApiBaseUrl: 'https://api.pipedrive.com/v1',
} as const

export const config = { ...getApiConfig(), ...appConfig }
