import { Permissions, UserInfo, UserTypes } from '@netpurpose/types'
import { convertStringToEnum } from '@netpurpose/utils'
import { GeneratedUsersApi } from '../../GeneratedApi'
import { Camelize } from '../../utils'
import { BackendUserGroup, parseUserGroups } from '../userGroups'

export type BackendUserGroupMembership = BackendUserGroup & {
  is_admin: boolean
}

export type BackendUserInfo = {
  user_id: string
  email: string
  first_name: string
  last_name: string
  groups: BackendUserGroupMembership[]
  type: string
  // TODO: update types to UserList and UserDetail
  permissions?: Permissions
  default_metrics_config_id: string
  active: boolean
  last_login: Date | null
}

export type BackendUserFormType = Pick<
  BackendUserInfo,
  | 'active'
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'type'
  | 'default_metrics_config_id'
  | 'last_login'
> & {
  group_id: string
}

export const parseUserInfo = (backendUserInfo: BackendUserInfo): UserInfo => ({
  userId: backendUserInfo.user_id,
  email: backendUserInfo.email,
  firstName: backendUserInfo.first_name,
  lastName: backendUserInfo.last_name,
  groups: parseUserGroups(backendUserInfo.groups),
  type: convertStringToEnum(backendUserInfo.type, UserTypes),
  ...(backendUserInfo?.permissions && { permissions: backendUserInfo.permissions }),
  defaultMetricsConfigId: backendUserInfo.default_metrics_config_id,
  active: backendUserInfo.active,
  lastLogin: backendUserInfo.last_login ? new Date(backendUserInfo.last_login) : null,
})

type APIUser = Awaited<ReturnType<(typeof GeneratedUsersApi)['users']['getUser']>>

export const transformUser = <UserProps extends Camelize<APIUser>>({
  type,
  lastLogin,
  ...rest
}: UserProps) => ({
  type: type ? convertStringToEnum<UserTypes>(type, UserTypes) : undefined,
  lastLogin: lastLogin ? new Date(lastLogin) : null,
  ...rest,
})
