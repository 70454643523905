import { format } from 'date-fns'
import {
  CloneEstimationType,
  CreateEstimationType,
  EstimationDatapointType,
} from '@netpurpose/types'
import { BACKEND_DATE_FORMAT } from '../datetime'
import { BackendWorkbook } from '../workbook'
import {
  BackendCloneEstimationForm,
  BackendEstimationCommon,
  BackendEstimationDatapoints,
} from './types'

export const formatEstimation = (
  estimation: CreateEstimationType,
): Omit<BackendEstimationCommon, 'workbook_id' | 'status'> & {
  clone_from?: BackendWorkbook['workbook_id']
} => ({
  entity_id: estimation.entityId,
  technology_id: estimation.technologyId,
  technology_name: estimation.technologyName,
  reporting_end_date: format(estimation.reportingEndDate, BACKEND_DATE_FORMAT),
  workbook_name: estimation.workbookName,
  ...(estimation.cloneEstimation ? { clone_from: estimation.cloneEstimation } : {}),
})

export const formatCloneEstimation = (
  cloneForm: CloneEstimationType,
): BackendCloneEstimationForm => ({
  source_entity_id: cloneForm.sourceEntityId,
  destination_entity_id: cloneForm.destinationEntityId,
  technology_id: cloneForm.technologyId,
})

const typeMap: Record<EstimationDatapointType, keyof BackendEstimationDatapoints> = {
  revenue: 'revenue',
  output: 'output',
  outcomeLevel1: 'outcome_level_1',
  activityCosts: 'activity_costs',
  baselineCosts: 'baseline_costs',
  outcomeLevel2s: 'outcome_level_2s',
  impact: 'impact',
  activityCost: 'activity_cost',
  baselineCost: 'baseline_cost',
  outcomeLevel2: 'outcome_level_2',
}

export const formatEstimationType = (type: EstimationDatapointType): string => typeMap[type]
