import { DatapointType } from '@netpurpose/types'
import { DatapointTypes, DatapointTypesEnum } from '../../generated/modelling'
import { BackendDatapoint } from './types'

export const datapointTypeMap: Record<DatapointType, DatapointTypes> = {
  [DatapointType.Datapoint]: DatapointTypesEnum.DATAPOINT,
  [DatapointType.Revenue]: DatapointTypesEnum.REVENUE,
  [DatapointType.Output]: DatapointTypesEnum.OUTPUT,
  [DatapointType.MacroFactor]: DatapointTypesEnum.MACRO_FACTOR,
  [DatapointType.OutcomeLevel1]: DatapointTypesEnum.OUTCOME_LEVEL_1,
  [DatapointType.OutcomeLevel2]: DatapointTypesEnum.OUTCOME_LEVEL_2,
  [DatapointType.TechnologyCost]: DatapointTypesEnum.TECHNOLOGY_COST,
  [DatapointType.Impact]: DatapointTypesEnum.IMPACT,
}

const reverseDatapointTypeMap = (
  Object.entries(datapointTypeMap) as [DatapointType, string][]
).reduce<Record<string, DatapointType>>(
  (acc, [k, v]) => ({
    ...acc,
    [v]: k,
  }),
  {},
)

export const parseDatapointType = (type: BackendDatapoint['type']) =>
  reverseDatapointTypeMap[type] || DatapointType.Datapoint
