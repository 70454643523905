export const getOptionalProperty = <Value, ParsedValue>(
  keyValuePair: [string, Value | undefined],
  parser?: (val: Value) => ParsedValue,
) => {
  if (keyValuePair[1] === undefined) {
    return {}
  }

  return parser
    ? { [keyValuePair[0]]: parser(keyValuePair[1]) }
    : { [keyValuePair[0]]: keyValuePair[1] }
}
