import axios, { AxiosInstance } from 'axios'
import qs, { IStringifyOptions } from 'qs'
import { mapError } from '../errors'
import { getAttachAuthTokenInterceptor } from './getAttachAuthTokenInterceptor'

type Service = 'facts' | 'modelling' | 'users'
type ServiceUrlConfig = {
  [key in Service as `${key}ServiceBaseUrl`]: string
}
type DefaultAxiosInstanceConfig = ServiceUrlConfig & {
  getAuthToken: () => Promise<string>
}
export type CustomAxiosInstance = ServiceUrlConfig & {
  getAxiosInstance: (baseUrl: string) => AxiosInstance
}
export type AxiosConfig = CustomAxiosInstance | DefaultAxiosInstanceConfig

export const qsConfig: IStringifyOptions = {
  skipNulls: true,
  arrayFormat: 'repeat',
}

export const encodeAndStringifyQueryParams = (params: { [key: string]: unknown }) =>
  encodeURIComponent(qs.stringify(params, qsConfig))

// use stringifyQueryParams with GeneratedApi q param (not encodeAndStringifyQueryParams)
// noticed with encodeAndStringifyQueryParams it doesn't respect the limit and defaults to 250
export const stringifyQueryParams = (params: { [key: string]: unknown }) =>
  qs.stringify(params, qsConfig)

const isCustomInstance = (config: AxiosConfig): config is CustomAxiosInstance =>
  (config as CustomAxiosInstance).getAxiosInstance !== undefined

const getServiceUrlKey = (service: Service): keyof ServiceUrlConfig => `${service}ServiceBaseUrl`

const getDefaultAxiosInstance = (config: DefaultAxiosInstanceConfig, serviceUrl: string) => {
  const { getAuthToken } = config
  const api = axios.create({
    baseURL: serviceUrl,
  })

  api.interceptors.request.use(getAttachAuthTokenInterceptor(getAuthToken))

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      return Promise.reject(mapError(error.response))
    },
  )

  return api
}

export type AxiosInstances = {
  api: AxiosInstance
  queryBuilderApi: AxiosInstance
}

const getServiceAxiosInstances = (config: AxiosConfig, service: Service): AxiosInstances => {
  const serviceUrl = config[getServiceUrlKey(service)]
  const { api, queryBuilderApi } = isCustomInstance(config)
    ? {
        api: config.getAxiosInstance(serviceUrl),
        queryBuilderApi: config.getAxiosInstance(serviceUrl),
      }
    : {
        api: getDefaultAxiosInstance(config, serviceUrl),
        queryBuilderApi: getDefaultAxiosInstance(config, serviceUrl),
      }

  api.defaults.paramsSerializer = (params) => qs.stringify(params, qsConfig)
  queryBuilderApi.defaults.paramsSerializer = (params) =>
    `q=${encodeAndStringifyQueryParams(params)}`

  return { api, queryBuilderApi }
}

export type AllAxiosInstances = {
  [key in Service]: AxiosInstances
}

export const getAxiosInstances = (config: AxiosConfig): AllAxiosInstances => ({
  facts: getServiceAxiosInstances(config, 'facts'),
  modelling: getServiceAxiosInstances(config, 'modelling'),
  users: getServiceAxiosInstances(config, 'users'),
})
