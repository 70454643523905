import {
  AcceptedFact,
  Aggregation,
  Fact,
  FactAnnotation,
  FactError,
  FactErrorType,
  FactState,
  HighYoYReason,
  LARGE_VARIANCE_REASONS,
  NO_REASON,
  SupportingFact,
} from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { HighlightArea } from '../../generated/facts'
import { ReverseFieldMap } from '../../queryBuilder'
import { BackendDataValue, parseDataValue } from '../dataValue'
import { parseDateFromBackend } from '../datetime'
import { BackendSource, parseSource } from '../source'

export type BackendReference = {
  highlight_areas?: HighlightArea[]
  page_num?: number
  reference_id?: number
  source?: BackendSource
  source_id: number
}

export type BackendSupportingFact = {
  supporting_fact_id: number
  fact_id: BackendFact['fact_id']
  recorded_value: BackendDataValue
  standardized_value: BackendDataValue
  total: boolean
  derived: boolean
  low_confidence: boolean
  internal_comment: string | null
  external_comment: string | null
  qualifier: string
  segment_id: number | null
  reference: BackendReference | null
}

export type BackendSupportingFactsById = Record<
  BackendSupportingFact['supporting_fact_id'],
  BackendSupportingFact
>

// only exists on WorkingFact + request payload when submitting
export type UnsubmittedFactError = {
  correct: boolean
  error_id: string
  error_type: string
  json_feedback: FactError['jsonFeedback']
}

export type BackendFactError = {
  correct: boolean | null
  error_id: number
  error_type: FactErrorType
  auto_qa_id: string | null
  cf_user_id: number | null
  description: string | null
  extraction_step_id: number | null
  np_user_id: string | null
  severity: number | null
  self_assessed: boolean
  json_feedback: FactError['jsonFeedback']
}

export const parseFactAnnotation = ({
  annotation_type,
  detail,
  reference,
  fact_annotation_id,
  fact_id,
}: BackendFactAnnotation): FactAnnotation | null => {
  if (detail !== NO_REASON && reference && valueIsDefined(reference.page_num)) {
    return {
      variant: 'withReference',
      factId: fact_id,
      id: fact_annotation_id,
      type: annotation_type,
      detail: LARGE_VARIANCE_REASONS.find((reason) => reason.name === detail)?.name || '',
      sourceId: reference.source_id,
      pageNum: reference.page_num,
      referenceId: reference.reference_id,
      source: reference?.source ? parseSource(reference.source) : undefined,
    }
  } else if (detail === NO_REASON) {
    return {
      variant: 'base',
      id: fact_annotation_id,
      factId: fact_id,
      type: annotation_type,
      detail,
    }
  }
  return null
}

export type BackendHighYoYReason = BackendFactAnnotation & { metric_name: string | undefined }

export const parseFactAnnotations = (annotations: BackendFactAnnotation[]): FactAnnotation[] =>
  annotations.map(parseFactAnnotation).filter(valueIsDefined)

const parseHighYoYReason = (highYoYReason: BackendHighYoYReason): HighYoYReason | null => {
  const factAnnotation = parseFactAnnotation(highYoYReason)
  if (factAnnotation) {
    return {
      ...factAnnotation,
      metricName: highYoYReason.metric_name,
    }
  }
  return null
}

const parseHighYoYReasons = (highYoYReasons: BackendHighYoYReason[]): HighYoYReason[] =>
  highYoYReasons.map(parseHighYoYReason).filter(valueIsDefined)

export const parseFactError = (error: BackendFactError): FactError => ({
  id: error.error_id,
  correct: error.correct,
  type: error.error_type,
  autoQAId: error.auto_qa_id,
  cfUserId: error.cf_user_id,
  description: error.description,
  extractionStepId: error.extraction_step_id,
  npUserId: error.np_user_id,
  severity: error.severity,
  selfAssessed: error.self_assessed,
  jsonFeedback: error.json_feedback,
})

export type BackendFactAnnotation = {
  fact_id?: number | undefined
  fact_annotation_id?: number | undefined
  annotation_type?: string | undefined
  detail: string
  reference: BackendReference | undefined
}

type BackendBaseFact = {
  fact_id: number
  value: BackendDataValue
  aggregation: Aggregation
  start: string | null
  end: string
  status: FactState
  metric_id: string
  entity_id: number
  supporting_facts: BackendSupportingFact[]
  sources_searched?: number[]
  year: number
}

export type BackendFact = BackendBaseFact & {
  derived?: boolean | null
  low_confidence?: boolean | null
  errors?: BackendFactError[]
  annotations?: BackendFactAnnotation[]
  feedback: string | null
  total_time_spent: number
}

export type BackendAcceptedFact = BackendBaseFact & {
  // TODO: remove the optional param once backend is in and definitely providing this on the API
  is_high_yoy?: boolean
  high_yoy_reasons?: BackendHighYoYReason[]
}

export const reverseFactFieldMap: ReverseFieldMap<
  | keyof Fact
  | keyof AcceptedFact
  | 'valueUnit'
  | 'valueUnitNotEquals'
  | 'state'
  | 'stateNotEquals'
  | 'startBefore'
  | 'startAfter'
  | 'endBefore'
  | 'endAfter'
  | 'entityName'
  | 'entitySector'
  | 'entityIndustry'
  | 'entitySector'
  | 'theme'
  | 'withErrors'
  | 'withContextFacts'
> = {
  id: 'fact_id',
  value: 'value',
  valueUnit: 'value.unit',
  valueUnitNotEquals: 'value.unit',
  aggregation: 'aggregation',
  start: 'start',
  startBefore: 'start',
  startAfter: 'start',
  end: 'end',
  endBefore: 'end',
  endAfter: 'end',
  status: 'status',
  state: 'status',
  stateNotEquals: 'status',
  metricId: 'metric_id',
  metricName: 'metric.name',
  theme: 'metric.themes',
  entityId: 'entity_id',
  entityName: 'entity.name',
  entityIndustry: 'entity.industry',
  entitySector: 'entity.sector',
  lowConfidence: 'low_confidence',
  year: 'year',
  errors: 'errors',
  annotations: 'annotations',
  derived: 'derived',
  sourcesSearched: 'sources_searched',
  taskId: 'task_id',
  feedback: 'feedback',
  totalTimeSpent: 'total_time_spent',
  supportingFacts: 'supporting_facts',
  // Query Modifiers
  withErrors: 'with_errors',
  withContextFacts: 'with_context_facts',
  highYoYReasons: 'high_yoy_reasons',
  isHighYoY: 'is_high_yoy',
  variant: '',
}

export type BackendFactWithTask = BackendFact & { task_id?: number }

// @ts-expect-error
const parseSupportingFact = (supportingFact: BackendSupportingFact): SupportingFact => ({
  id: supportingFact.supporting_fact_id,
  factId: supportingFact.fact_id,
  recordedValue: parseDataValue(supportingFact.recorded_value),
  standardizedValue: parseDataValue(supportingFact.standardized_value),
  isTotal: supportingFact.total,
  isDerived: supportingFact.derived,
  isLowConfidence: supportingFact.low_confidence,
  externalComment: supportingFact.external_comment || undefined,
  internalComment: supportingFact.internal_comment || undefined,
  qualifier: supportingFact.qualifier,
  segmentId: supportingFact.segment_id,
  sourceId: supportingFact.reference?.source_id || undefined,
  page: supportingFact.reference?.page_num || undefined,
})

const parseBaseFact = <FactType extends BackendBaseFact>(fact: FactType) => ({
  id: fact.fact_id,
  value: parseDataValue(fact.value),
  aggregation: fact.aggregation,
  start: fact.start ? parseDateFromBackend(fact.start) : undefined,
  end: parseDateFromBackend(fact.end),
  status: fact.status,
  metricId: fact.metric_id,
  entityId: fact.entity_id,
  supportingFacts: fact.supporting_facts.map((sf) => parseSupportingFact(sf)),
  sourcesSearched: fact.sources_searched || [],
  year: fact.year,
})

// @ts-expect-error
export const parseAcceptedFact = (fact: BackendAcceptedFact): AcceptedFact => ({
  ...parseBaseFact(fact),
  variant: 'AcceptedFact',
  // TODO: remove the optional param selection and or case here once backend is in and definitely providing this on the API
  isHighYoY: fact?.is_high_yoy || false,
  highYoYReasons: parseHighYoYReasons(fact?.high_yoy_reasons || []),
})

// @ts-expect-error
export const parseFact = (fact: BackendFact): Fact => ({
  ...parseBaseFact(fact),
  variant: 'Fact',
  lowConfidence: fact.low_confidence ?? undefined,
  derived: fact.derived ?? undefined,
  errors: fact.errors?.map(parseFactError) || [],
  annotations: parseFactAnnotations(fact.annotations || []),
  feedback: fact.feedback,
  totalTimeSpent: fact.total_time_spent,
})

export const factStateNames = {
  [FactState.Created]: 'Created',
  [FactState.Extracted]: 'Extracted',
  [FactState.FlaggedForExpert]: 'Flagged for Expert',
  [FactState.Accepted]: 'Accepted',
  [FactState.Deprecated]: 'Deprecated',
  [FactState.Verified]: 'Verified',
  [FactState.PrematureUpdate]: 'Premature',
} as const
